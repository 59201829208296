import { useTranslation } from 'react-i18next';
// '@mui
import { enUS, deDE, frFR, heIL } from '@mui/material/locale';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/images/ic_flag_en.svg',
  },
  {
    label: 'Hebrew',
    value: 'he',
    systemValue: heIL,

    icon: '/images/ic_flag_he.png',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation(["NavBar"]);
  const { onChangeDirection } = useSettings();

  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    localStorage.setItem("i18nextLng", newlang);
    i18n.changeLanguage(newlang);
    var e = {target:{value:'rtl'}}
    if(newlang == "en"){
      e.target.value = 'ltr'
    }
    onChangeDirection(e)

  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
