import * as Yup from 'yup';
import { Dialog, Box, Grid, Card, TextField, Stack, Alert, Button, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { DataService } from '../DataService';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { API_ENDPOINT } from '../../config'
import { useEffect } from 'react';
import InputMask from "react-input-mask";
export default function ChangePassword({ close, handleSuccess, showCloseButton = true, data = null }) {
  const { t } = useTranslation("Email_Confirmation")
  const { enqueueSnackbar } = useSnackbar();
  const NewSchema = Yup.object().shape({
    // email: Yup.string().required(t('Email_Required')),
    otp: Yup.string().min(8, t('OTP_Min')).required(t('OTP_Required')),
  });

  function HideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/,
      function(gp1, gp2, gp3) { 
        for(let i = 0; i < gp3.length; i++) { 
          gp2+= "*"; 
        } return gp2; 
      });
  };

  useEffect(()=>{
    formik.setFieldValue("email", data?.user?.email)
  },[])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      otp: '',
    },
    validationSchema: NewSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      var token = localStorage.getItem("accessToken");
      if (data != null) {
        token = data.token;
      }
      var uri = encodeURI(`${API_ENDPOINT}Users/EmailConfirmation?otp=${values.otp}`)
      const headers = { Authorization: `Bearer ${token}` };
      axios.put(uri, null, { headers })
        .then(function (res) {
          if (res.data.success) {
            enqueueSnackbar(t("Success_Message"));
            handleSuccess(data);
            close();
          } else if (res.data.success == false) {
            if (res.data.errorCode == 1008) {
              formik.setFieldError("otp", t('Invalid_OTP'))
            } else {
              setErrors({ afterSubmit: res.data.message });
            }
          }
        })
        .catch(function (error) {
          console.log(error)
          if (error.response) {
            if (error.response.data) {
              var data = error.response.data;
              if (data.errorCode == 1008) {
                formik.setFieldError("otp", t('Invalid_OTP'))
              } else {
                setErrors({ afterSubmit: data.message });
              }
            }
          }
        });
    },
  });
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps, resetForm } = formik;


  return (
    <Dialog fullWidth open>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <h2>{t('Title')}</h2>
                    <p>{t('SubTitle')}</p>
                  </div>
                </Box>

                <br />
                <Stack spacing={3}>
                  {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={t("Email")}
                      {...getFieldProps('email')}
                      disabled
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <InputMask
                      fullWidth
                      autoFocus
                      mask="99999999"
                      label={t("OTP")}
                      {...formik.getFieldProps('otp')}
                      error={Boolean(formik.touched.otp && formik.errors.otp)}
                      helperText={formik.touched.otp && formik.errors.otp}
                    >
                      {(inputProps) => (
                        <TextField {...inputProps} />
                      )}
                    </InputMask>
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    {showCloseButton &&
                      <Button sx={{ mr: 1 }} variant="outlined" onClick={() => close()}>
                        {t('Close')}
                      </Button>}
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {t('Confirm')}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
