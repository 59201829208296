// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShareIcon from '@mui/icons-material/Share';
import ColorLensIcon from '@mui/icons-material/ColorLens';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: <DashboardIcon />,
  booking: getIcon('ic_booking'),
  list: <ViewListIcon />,
  company: <BusinessIcon />,
  newListItem: <AddIcon />,
  editListItem: <EditIcon />,
  log:<ListAltIcon/>,
  domain: <ShareIcon/>,
  logo: <ColorLensIcon/>,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // {
      //   title: 'dashboard',
      //   path: PATH_DASHBOARD.general.app,
      //   icon: ICONS.dashboard,
      //   role: [1]
      // },
      {
        title: 'list',
        path: PATH_DASHBOARD.link.root,
        icon: ICONS.list,
        role: [1,2,3]
        //subMenu: {path: PATH_DASHBOARD.user.newUser, icon: ICONS.newListItem}
      },
      {
        title: 'create',
        path: PATH_DASHBOARD.link.new, 
        icon: ICONS.newListItem,
        role: [1,2,3]
      },
      {
        title: 'companies',
        path: PATH_DASHBOARD.company.root, 
        icon: ICONS.company,
        role: [1]
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.allUser.root, 
        icon: ICONS.user,
        role: [1]
      },
      {
        title: 'domain',
        path: PATH_DASHBOARD.allDomains.root, 
        icon: ICONS.domain,
        role: [1]
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.user.root, 
        icon: ICONS.user,
        role: [2]
      },
      {
        title: 'domain',
        path: PATH_DASHBOARD.domains.root, 
        icon: ICONS.domain,
        role: [2, 3]
      },
      {
        title: 'logo',
        path: PATH_DASHBOARD.logo.root, 
        icon: ICONS.logo,
        role: [2]
      },
      {
        title: 'logs',
        path: PATH_DASHBOARD.logs.root, 
        icon: ICONS.log,
        role: [1]
      }
      // {
      //   title: 'edit',
      //   path: PATH_DASHBOARD.user.editById, 
      //   icon: ICONS.editListItem
      // },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // MANAGEMENT : E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
  //       ],
  //     },

  //     // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
